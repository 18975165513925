<template>
  <div class="wrap">
    <header-nav></header-nav>
    <div class="new-product">
      <div class="new-product-in">
        <div class="new-product-title">
          <div class="new-product-img-in">
            <img src="@/../public/icon/mall/newProduct/new_goods_title.png" alt="">
          </div>
        </div>
        <b-container>
          <b-row class="b-row">
            <b-col class="b-col" sm="6" md="6" lg="4" xl="4" v-for="(item, index) in newProducts" :key="index">
              <div class="item-wrap" @click="toProductDetail(item)">
                <div class="pic">
                  <img :src="item.file_path" :alt="item.goods_name">
                </div>
                <div class="detail">
                  <div class="name">{{item.style_name}} {{item.goods_name}}</div>
                  <div class="price">￥{{item.sale_price}}</div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="pagination">
                <el-pagination
                  background
                  hide-on-single-page
                  layout="pager"
                  @current-change="pageSizeChange"
                  :page-size="pageSize"
                  :total="total">
                </el-pagination>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <right></right>
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import headerNav from "@/components/Navbar";
  import Right from "@/components/Right.vue";
  import footerNav from "@/components/Footer";
  import dump from "@/util/dump";
  export default {
    components: {
      headerNav,
      Right,
      footerNav
    },
    data (){
      return {
        token: null,
        newProducts: [],
        currentPage: 1,
        pageSize: 33,
        total: 0
      }
    },
    created() {
      this.init();
    },
    methods: {
      init(){
        this.token = localStorage.getItem("token");
        this.getNewProduct();
      },
      // 获取新品推荐的商品
      getNewProduct(){
        this.$api.newProduct({
          currentPage: this.currentPage,
          sort_type: 2,
          token: this.token
        }).then(res => {
          if(res.status == 100){
            this.newProducts = res.data.goods;
            this.total = res.data.totalResult;
          }
        }).catch(err => console.error(err));
      },
      // 分页切换
      pageSizeChange(num){
        this.currentPage = num;
        this.getNewProduct();
        this.$utils.scrollTop();
      },
      // 跳转到商品详情
      toProductDetail(obj){
        dump.link({
          type: 3,
          link: "/product/details",
          params: {
            good_id: obj.goods_id
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/view/mall/newProduct/newProduct";
</style>
